.eg-card {
  display: flex;
  padding: 15px;
}

.eg-card .Card__in {
  display: flex;
  width: 100%;
}

.eg-game-description {
  opacity: 0.6;
  font-size: 14px;
}