.winWrap {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
  color: #fff;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: opacity 0.3s ease;
  padding: 15px;
}

.resultTitle {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
  margin: 0;
  margin-bottom: 20px;
  text-align: center;
}

.buttonsWrapper {
  width: 100%;
}

.button, .buttonJoin {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 25px;
  min-height: 42px;
}

.buttonJoin {
  background-color: #485767;
}

.buttonJoin::after {
  display: none;
}

.button svg {
  width: 18px;
  height: 18px;
}

.hiddenButton {
  pointer-events: none;
  position: relative;
  z-index: -1;
  opacity: 0;
}

.resultSubtitle {
  font-size: 16px;
  color: #fff;
  margin: 0;
  font-weight: normal;
  text-align: center;
  margin-bottom: 10px;
  max-width: 500px;
}

.resultSubtitleError {
  font-size: 16px;
  color: #ff0000;
  margin: 0;
  font-weight: normal;
  text-align: center;
  margin-bottom: 10px;
  max-width: 500px;
}

.linkToPrize {
  color: #fff;
  text-decoration: underline;
}

.copy {
  text-decoration: underline;
  font-size: 12px;
  color: #fff;
  font-weight: normal;
  text-align: center;
  cursor: pointer;
}

.copyCopyed {
  animation: eg_copy_2271 1s linear infinite;
  transform-origin: 50% 50%;
}

.copy:hover {
  color: rgba(255, 255, 255, 0.9);
}
