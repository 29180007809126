.option {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;

  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  transition: background-color 0.2s ease;
  background: #fff;
  color: #0b1f35;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}
.option:not(.disabled):active {
  background: #e7e9eb;
  color: #0b1f35;
}
.option:before {
  content: '';
  position: absolute;
  left: 12px;
  right: 12px;
  top: 0;
  height: 1px;
  background: #dbdee1;
  display: none;
  transition: opacity 0.2s ease;
}
.option:first-child:before {
  display: none;
}
.s {
  min-height: 48px;
}
.m {
  min-height: 56px;
}
.l {
  min-height: 64px;
}
.xl {
  min-height: 72px;
}
.l,
.xl {
  padding-left: 16px;
  padding-right: 16px;
}
.l:before,
.xl:before {
  left: 16px;
  right: 16px;
}
.selected {
  background: #fff;
  color: #0b1f35;
  cursor: default;
}
.highlighted {
  background: #f3f4f5;
  color: #0b1f35;
}
.highlighted:before,
.highlighted + .option:before {
  opacity: 0;
}
.disabled {
  cursor: not-allowed;
  background: #fff;
  color: #546272;
}
/* IE min-height fix */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .option:after {
    min-height: inherit;
    font-size: 0;
    content: '';
  }
}
