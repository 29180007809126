.component {
  width: max-content;
  position: relative;
  outline: 0;
}
.popover {
  padding-top: 0;

  padding-bottom: 0;

  box-shadow: none;
  border: none;
  background: transparent;
  border-radius: 0;
  position: relative;

  /* TODO: перейти на систему */
  z-index: 9999;
}
.optionsList {
  padding-top: 0;

  padding-bottom: 0;

  box-shadow: 0 0 8px rgba(11, 31, 53, 0.04), 0 4px 8px rgba(11, 31, 53, 0.08);

  border-radius: 0 0 4px 4px;

  background: #fff;

  overflow: hidden;
  will-change: transform;
}
.nativeSelect {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  min-width: 0;
  height: 100%;
  cursor: pointer;
  z-index: 1;
}
.block {
  width: 100%;
}
/* width: max-content; fix for IE */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .component {
    float: left;
    clear: left;
  }
}

.popper {
  z-index: 10000;
}