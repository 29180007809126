.mainSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.prizeRow {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.prize {
  width: 100px;
  height: 120px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.prizeRow0 {
  display: none;
}

.prizeRow1 {
  justify-content: center;
}

.secondSection {
  justify-content: flex-start;
}

.emailInput {
  margin: 0;
  margin-top: 25px;
}

.button {
  background-color: #028dfd;
  color: #fff;
}

@media screen and (min-width: 360px) {
  .prize {
    width: 140px;
    height: 160px;
  }
}

@media screen and (min-width: 768px) {
  .prizeRow0 {
    display: flex;
  }

  .prizeSection {
    flex-grow: 1;
  }

  .emailInput {
    margin: 0;
  }
}

@keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }
  75% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
  }
}

@keyframes bbb {
  0% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
  }
  100% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

.hvrPulse {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-animation-name: hvr-pulse;
  animation-name: hvr-pulse;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.prize:hover {
  animation-name: bbb;
  -webkit-animation-name: bbb;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}
