.gift {
  position: fixed;
  z-index: 100000;
  cursor: pointer;
  bottom: -15px;
  width: 100px;
  height: 100px;
  transform: rotate(10deg);
  background-size: contain;
  background-repeat: no-repeat;
  animation: 5s linear 0s infinite;
  animation-name: tada;
}

.giftHidden {
  transform: scale(0.9);
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}

.giftShadow {
  position: fixed;
  bottom: 0;
  width: 20px;
  height: 20px;
  border-radius: 100px;
  content: '';
  box-shadow: 0 0 100px 50px #d91d0b;
  z-index: 99999;
}

.gift.mobile-left {
  left: -15px;
}

.gift.mobile-right {
  right: -25px;
  transform: rotate(-10deg);
  animation-name: tada-right;
}

.giftShadow.mobile-left {
  left: -20px;
}

.giftShadow.mobile-right {
  right: -20px;
}

@media screen and (min-width: 960px) {
  .gift {
    width: 150px;
    height: 150px;
    transform: rotate(10deg);
    bottom: -20px;

    &:hover {
      transform: rotate(10deg) scale(1.1);
    }
  }

  .giftShadow {
    box-shadow: 0 0 150px 75px #d91d0b;
  }

  .gift.mobile-left {
    left: initial;
  }

  .gift.mobile-right {
    right: initial;
  }

  .giftShadow.mobile-left {
    left: initial;
  }

  .giftShadow.mobile-right {
    right: initial;
  }

  .gift.desktop-left {
    left: -20px;
  }

  .gift.desktop-right {
    right: -30px;
    transform: rotate(-10deg);
    animation-name: tada-right;
  }

  .giftShadow.desktop-left {
    left: -20px;
  }

  .giftShadow.desktop-right {
    right: -30px;
  }
}

@keyframes tada {
  0% {
    transform: scale3d(1, 1, 1) rotate(10deg);
  }

  2%,
  4% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  6%,
  10%,
  14%,
  18% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  8%,
  12%,
  16% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  20% {
    transform: scale3d(1, 1, 1) rotate(10deg);
  }
}

@keyframes tada-right {
  0% {
    transform: scale3d(1, 1, 1) rotate(-10deg);
  }

  2%,
  4% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  6%,
  10%,
  14%,
  18% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  8%,
  12%,
  16% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  20% {
    transform: scale3d(1, 1, 1) rotate(-10deg);
  }
}
