.flagIcon {
  display: inline-flex;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 0 2px 0 rgba(11, 31, 53, 0.25);
}

.s {
  background-image: url('https://cdn2.embedgames.app/images/flag-icon_size_s@1x.png');
  background-size: 195px 189px;
}

@media only screen and (min-device-pixel-ratio: 2),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .s {
    background-image: url('https://cdn2.embedgames.app/images/flag-icon_size_s@2x.png');
  }
}

.m {
  background-image: url('https://cdn2.embedgames.app/images/flag-icon_size_m@1x.png');
  background-size: 278px 254px;
}

@media only screen and (min-device-pixel-ratio: 2),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .m {
    background-image: url('https://cdn2.embedgames.app/images/flag-icon_size_m@2x.png');
  }
}

.l,
.xl {
  background-image: url('https://cdn2.embedgames.app/images/flag-icon_size_l@1x.png');
  background-size: 300px 275px;
}

@media only screen and (min-device-pixel-ratio: 2),
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .l,
  .xl {
    background-image: url('https://cdn2.embedgames.app/images/flag-icon_size_l@2x.png');
  }
}

/* sprite size s */

.s.ac {
  width: 15px;
  height: 8px;
  background-position: -60px -50px;
}

.s.ad {
  width: 15px;
  height: 11px;
  background-position: -28px -24px;
}

.s.ae {
  width: 15px;
  height: 8px;
  background-position: -180px -24px;
}

.s.af {
  width: 15px;
  height: 10px;
  background-position: -75px -108px;
}

.s.ag {
  width: 15px;
  height: 10px;
  background-position: -105px -108px;
}

.s.ai {
  width: 15px;
  height: 8px;
  background-position: -180px -120px;
}

.s.al {
  width: 15px;
  height: 11px;
  background-position: -45px 0;
}

.s.am {
  width: 15px;
  height: 8px;
  background-position: -60px -165px;
}

.s.ao {
  width: 15px;
  height: 10px;
  background-position: -60px -10px;
}

.s.aq {
  width: 15px;
  height: 10px;
  background-position: -60px -30px;
}

.s.ar {
  width: 15px;
  height: 10px;
  background-position: -15px -58px;
}

.s.as {
  width: 15px;
  height: 8px;
  background-position: -120px -157px;
}

.s.at {
  width: 15px;
  height: 10px;
  background-position: -15px -78px;
}

.s.au {
  width: 15px;
  height: 8px;
  background-position: -120px -165px;
}

.s.aw {
  width: 15px;
  height: 10px;
  background-position: -90px -50px;
}

.s.ax {
  width: 15px;
  height: 10px;
  background-position: -90px -70px;
}

.s.az {
  width: 15px;
  height: 8px;
  background-position: -135px -173px;
}

.s.ba {
  width: 15px;
  height: 8px;
  background-position: -165px -111px;
}

.s.bb {
  width: 15px;
  height: 10px;
  background-position: -15px -98px;
}

.s.bd {
  width: 15px;
  height: 9px;
  background-position: -165px -18px;
}

.s.be {
  width: 14px;
  height: 12px;
  background-position: -14px -24px;
}

.s.bf {
  width: 15px;
  height: 10px;
  background-position: -120px -70px;
}

.s.bg {
  width: 15px;
  height: 9px;
  background-position: -165px -9px;
}

.s.bh {
  width: 15px;
  height: 9px;
  background-position: -165px 0;
}

.s.bi {
  width: 15px;
  height: 9px;
  background-position: -150px -148px;
}

.s.bj {
  width: 15px;
  height: 10px;
  background-position: -60px -138px;
}

.s.bl {
  width: 15px;
  height: 10px;
  background-position: -105px -138px;
}

.s.bm {
  width: 15px;
  height: 8px;
  background-position: -15px -157px;
}

.s.bn {
  width: 15px;
  height: 8px;
  background-position: -60px -157px;
}

.s.bo {
  width: 15px;
  height: 10px;
  background-position: -45px -47px;
}

.s.bq {
  width: 15px;
  height: 10px;
  background-position: -60px 0;
}

.s.br {
  width: 15px;
  height: 11px;
  background-position: 0 -36px;
}

.s.bs {
  width: 15px;
  height: 8px;
  background-position: -135px -165px;
}

.s.bt {
  width: 15px;
  height: 10px;
  background-position: -60px -20px;
}

.s.bv {
  width: 15px;
  height: 11px;
  background-position: -30px -36px;
}

.s.bw {
  width: 15px;
  height: 10px;
  background-position: -60px -40px;
}

.s.by {
  width: 15px;
  height: 8px;
  background-position: -15px -181px;
}

.s.bz {
  width: 15px;
  height: 10px;
  background-position: 0 -58px;
}

.s.ca {
  width: 15px;
  height: 8px;
  background-position: -165px -119px;
}

.s.cc {
  width: 15px;
  height: 8px;
  background-position: -90px -80px;
}

.s.cd {
  width: 15px;
  height: 12px;
  background-position: -30px -12px;
}

.s.cf {
  width: 15px;
  height: 10px;
  background-position: -30px -58px;
}

.s.cg {
  width: 15px;
  height: 10px;
  background-position: -45px -58px;
}

.s.ch {
  width: 12px;
  height: 12px;
  background-position: -150px -42px;
}

.s.ci {
  width: 15px;
  height: 10px;
  background-position: -75px 0;
}

.s.ck {
  width: 15px;
  height: 8px;
  background-position: -105px -165px;
}

.s.cl {
  width: 15px;
  height: 10px;
  background-position: -75px -10px;
}

.s.cm {
  width: 15px;
  height: 10px;
  background-position: -75px -20px;
}

.s.cn {
  width: 15px;
  height: 10px;
  background-position: -75px -30px;
}

.s.co {
  width: 15px;
  height: 10px;
  background-position: -75px -40px;
}

.s.cp {
  width: 15px;
  height: 10px;
  background-position: -75px -50px;
}

.s.cr {
  width: 15px;
  height: 9px;
  background-position: -105px -148px;
}

.s.cu {
  width: 15px;
  height: 8px;
  background-position: -75px -181px;
}

.s.cv {
  width: 15px;
  height: 9px;
  background-position: -90px -148px;
}

.s.cw {
  width: 15px;
  height: 10px;
  background-position: -30px -68px;
}

.s.cx {
  width: 15px;
  height: 8px;
  background-position: -135px -120px;
}

.s.cy {
  width: 15px;
  height: 10px;
  background-position: -45px -68px;
}

.s.cz {
  width: 15px;
  height: 10px;
  background-position: -60px -68px;
}

.s.de {
  width: 15px;
  height: 9px;
  background-position: -75px -148px;
}

.s.dg {
  width: 15px;
  height: 8px;
  background-position: -30px -157px;
}

.s.dj {
  width: 15px;
  height: 10px;
  background-position: 0 -78px;
}

.s.dk {
  width: 15px;
  height: 12px;
  background-position: -30px 0;
}

.s.dm {
  width: 15px;
  height: 8px;
  background-position: 0 -165px;
}

.s.do {
  width: 15px;
  height: 10px;
  background-position: -30px -78px;
}

.s.dz {
  width: 15px;
  height: 10px;
  background-position: -45px -78px;
}

.s.ea {
  width: 15px;
  height: 10px;
  background-position: -60px -78px;
}

.s.ec {
  width: 15px;
  height: 10px;
  background-position: -75px -78px;
}

.s.ee {
  width: 15px;
  height: 10px;
  background-position: -90px 0;
}

.s.eg {
  width: 15px;
  height: 10px;
  background-position: -90px -10px;
}

.s.eh {
  width: 15px;
  height: 8px;
  background-position: -180px -136px;
}

.s.er {
  width: 15px;
  height: 8px;
  background-position: -90px -173px;
}

.s.es {
  width: 15px;
  height: 10px;
  background-position: -90px -20px;
}

.s.et {
  width: 15px;
  height: 8px;
  background-position: -150px -173px;
}

.s.eu {
  width: 15px;
  height: 10px;
  background-position: -90px -30px;
}

.s.fi {
  width: 15px;
  height: 9px;
  background-position: -45px -148px;
}

.s.fj {
  width: 15px;
  height: 8px;
  background-position: -165px -43px;
}

.s.fk {
  width: 15px;
  height: 8px;
  background-position: -165px -51px;
}

.s.fm {
  width: 15px;
  height: 8px;
  background-position: -165px -103px;
}

.s.fo {
  width: 15px;
  height: 11px;
  background-position: 0 -47px;
}

.s.fr {
  width: 15px;
  height: 10px;
  background-position: -90px -60px;
}

.s.ga {
  width: 15px;
  height: 12px;
  background-position: 0 0;
}

.s.gb {
  width: 15px;
  height: 8px;
  background-position: -120px -110px;
}

.s.gd {
  width: 15px;
  height: 9px;
  background-position: -30px -148px;
}

.s.ge {
  width: 15px;
  height: 10px;
  background-position: -15px -88px;
}

.s.gf {
  width: 15px;
  height: 10px;
  background-position: -30px -88px;
}

.s.gg {
  width: 15px;
  height: 10px;
  background-position: -45px -88px;
}

.s.gh {
  width: 15px;
  height: 10px;
  background-position: -60px -88px;
}

.s.gi {
  width: 15px;
  height: 8px;
  background-position: -90px -157px;
}

.s.gl {
  width: 15px;
  height: 10px;
  background-position: -75px -88px;
}

.s.gm {
  width: 15px;
  height: 10px;
  background-position: -90px -88px;
}

.s.gn {
  width: 15px;
  height: 10px;
  background-position: -105px 0;
}

.s.gp {
  width: 15px;
  height: 10px;
  background-position: -105px -10px;
}

.s.gq {
  width: 15px;
  height: 10px;
  background-position: -105px -20px;
}

.s.gr {
  width: 15px;
  height: 10px;
  background-position: -105px -30px;
}

.s.gs {
  width: 15px;
  height: 8px;
  background-position: -180px -16px;
}

.s.gt {
  width: 15px;
  height: 10px;
  background-position: -105px -40px;
}

.s.gu {
  width: 15px;
  height: 8px;
  background-position: -180px -64px;
}

.s.gw {
  width: 15px;
  height: 8px;
  background-position: -180px -72px;
}

.s.gy {
  width: 15px;
  height: 9px;
  background-position: -150px -54px;
}

.s.hk {
  width: 15px;
  height: 10px;
  background-position: -105px -60px;
}

.s.hm {
  width: 15px;
  height: 8px;
  background-position: -180px -160px;
}

.s.hn {
  width: 15px;
  height: 8px;
  background-position: -15px -173px;
}

.s.hr {
  width: 15px;
  height: 8px;
  background-position: -30px -173px;
}

.s.ht {
  width: 15px;
  height: 9px;
  background-position: 0 -148px;
}

.s.hu {
  width: 15px;
  height: 8px;
  background-position: -120px -173px;
}

.s.ic {
  width: 15px;
  height: 10px;
  background-position: -105px -80px;
}

.s.id {
  width: 15px;
  height: 10px;
  background-position: 0 -98px;
}

.s.ie {
  width: 15px;
  height: 8px;
  background-position: -180px -173px;
}

.s.il {
  width: 15px;
  height: 11px;
  background-position: -45px -11px;
}

.s.im {
  width: 15px;
  height: 8px;
  background-position: -30px -181px;
}

.s.in {
  width: 15px;
  height: 10px;
  background-position: -30px -98px;
}

.s.io {
  width: 15px;
  height: 8px;
  background-position: -165px -35px;
}

.s.iq {
  width: 15px;
  height: 10px;
  background-position: -45px -98px;
}

.s.ir {
  width: 15px;
  height: 9px;
  background-position: -150px -135px;
}

.s.is {
  width: 15px;
  height: 11px;
  background-position: -45px -22px;
}

.s.it {
  width: 15px;
  height: 10px;
  background-position: -90px -98px;
}

.s.je {
  width: 15px;
  height: 9px;
  background-position: -150px -126px;
}

.s.jm {
  width: 15px;
  height: 8px;
  background-position: -165px -127px;
}

.s.jo {
  width: 15px;
  height: 8px;
  background-position: -165px -143px;
}

.s.jp {
  width: 15px;
  height: 10px;
  background-position: 0 -108px;
}

.s.ke {
  width: 15px;
  height: 10px;
  background-position: -15px -108px;
}

.s.kg {
  width: 15px;
  height: 9px;
  background-position: -150px -108px;
}

.s.kh {
  width: 15px;
  height: 10px;
  background-position: -45px -108px;
}

.s.ki {
  width: 15px;
  height: 8px;
  background-position: 0 -157px;
}

.s.km {
  width: 15px;
  height: 9px;
  background-position: -150px -99px;
}

.s.kn {
  width: 15px;
  height: 10px;
  background-position: -30px -47px;
}

.s.kp {
  width: 15px;
  height: 8px;
  background-position: -45px -157px;
}

.s.kr {
  width: 15px;
  height: 10px;
  background-position: -90px -108px;
}

.s.kw {
  width: 15px;
  height: 8px;
  background-position: -75px -157px;
}

.s.ky {
  width: 15px;
  height: 8px;
  background-position: -165px -27px;
}

.s.kz {
  width: 15px;
  height: 8px;
  background-position: -105px -157px;
}

.s.la {
  width: 15px;
  height: 10px;
  background-position: -120px 0;
}

.s.lb {
  width: 15px;
  height: 10px;
  background-position: -120px -10px;
}

.s.lc {
  width: 15px;
  height: 8px;
  background-position: -15px -165px;
}

.s.li {
  width: 15px;
  height: 9px;
  background-position: -150px -90px;
}

.s.lk {
  width: 15px;
  height: 8px;
  background-position: -75px -165px;
}

.s.lr {
  width: 15px;
  height: 8px;
  background-position: -90px -165px;
}

.s.ls {
  width: 15px;
  height: 10px;
  background-position: -120px -30px;
}

.s.lt {
  width: 15px;
  height: 9px;
  background-position: -150px -81px;
}

.s.lu {
  width: 15px;
  height: 9px;
  background-position: -150px -72px;
}

.s.lv {
  width: 15px;
  height: 8px;
  background-position: -150px -165px;
}

.s.ly {
  width: 15px;
  height: 8px;
  background-position: -180px -8px;
}

.s.ma {
  width: 15px;
  height: 10px;
  background-position: -120px -60px;
}

.s.mc {
  width: 15px;
  height: 12px;
  background-position: -15px -12px;
}

.s.md {
  width: 15px;
  height: 8px;
  background-position: -180px -32px;
}

.s.me {
  width: 15px;
  height: 8px;
  background-position: -180px -56px;
}

.s.mf {
  width: 15px;
  height: 10px;
  background-position: -120px -80px;
}

.s.mg {
  width: 15px;
  height: 10px;
  background-position: -120px -90px;
}

.s.mh {
  width: 15px;
  height: 8px;
  background-position: -180px -80px;
}

.s.mk {
  width: 15px;
  height: 8px;
  background-position: -180px -112px;
}

.s.ml {
  width: 15px;
  height: 10px;
  background-position: -120px -100px;
}

.s.mm {
  width: 15px;
  height: 10px;
  background-position: 0 -118px;
}

.s.mn {
  width: 15px;
  height: 8px;
  background-position: -180px -144px;
}

.s.mo {
  width: 15px;
  height: 10px;
  background-position: -15px -118px;
}

.s.mp {
  width: 15px;
  height: 8px;
  background-position: 0 -173px;
}

.s.mq {
  width: 15px;
  height: 10px;
  background-position: -30px -118px;
}

.s.mr {
  width: 15px;
  height: 10px;
  background-position: -45px -118px;
}

.s.ms {
  width: 15px;
  height: 8px;
  background-position: -60px -173px;
}

.s.mt {
  width: 15px;
  height: 10px;
  background-position: -60px -118px;
}

.s.mu {
  width: 15px;
  height: 10px;
  background-position: -75px -118px;
}

.s.mv {
  width: 15px;
  height: 10px;
  background-position: -90px -118px;
}

.s.mw {
  width: 15px;
  height: 10px;
  background-position: -105px -118px;
}

.s.mx {
  width: 15px;
  height: 9px;
  background-position: -150px -63px;
}

.s.my {
  width: 15px;
  height: 8px;
  background-position: 0 -181px;
}

.s.mz {
  width: 15px;
  height: 10px;
  background-position: -135px 0;
}

.s.na {
  width: 15px;
  height: 10px;
  background-position: -135px -10px;
}

.s.nc {
  width: 15px;
  height: 8px;
  background-position: -60px -181px;
}

.s.ne {
  width: 14px;
  height: 12px;
  background-position: 0 -24px;
}

.s.nf {
  width: 15px;
  height: 8px;
  background-position: -90px -181px;
}

.s.ng {
  width: 15px;
  height: 8px;
  background-position: -105px -181px;
}

.s.ni {
  width: 15px;
  height: 9px;
  background-position: -120px -148px;
}

.s.nl {
  width: 15px;
  height: 10px;
  background-position: -135px -40px;
}

.s.no {
  width: 15px;
  height: 11px;
  background-position: -45px -36px;
}

.s.np {
  width: 10px;
  height: 12px;
  background-position: -165px -59px;
}

.s.nr {
  width: 15px;
  height: 8px;
  background-position: -165px -71px;
}

.s.nu {
  width: 15px;
  height: 8px;
  background-position: -165px -79px;
}

.s.nz {
  width: 15px;
  height: 8px;
  background-position: -165px -87px;
}

.s.om {
  width: 15px;
  height: 8px;
  background-position: -165px -95px;
}

.s.pa {
  width: 15px;
  height: 10px;
  background-position: -135px -60px;
}

.s.pe {
  width: 15px;
  height: 10px;
  background-position: -135px -70px;
}

.s.pf {
  width: 15px;
  height: 10px;
  background-position: -135px -80px;
}

.s.pg {
  width: 15px;
  height: 12px;
  background-position: 0 -12px;
}

.s.ph {
  width: 15px;
  height: 8px;
  background-position: -165px -135px;
}

.s.pk {
  width: 15px;
  height: 10px;
  background-position: -135px -100px;
}

.s.pl {
  width: 15px;
  height: 10px;
  background-position: -135px -110px;
}

.s.pm {
  width: 15px;
  height: 10px;
  background-position: 0 -128px;
}

.s.pn {
  width: 15px;
  height: 8px;
  background-position: -105px -90px;
}

.s.pr {
  width: 15px;
  height: 10px;
  background-position: -15px -128px;
}

.s.ps {
  width: 15px;
  height: 8px;
  background-position: -75px -60px;
}

.s.pt {
  width: 15px;
  height: 10px;
  background-position: -30px -128px;
}

.s.pw {
  width: 15px;
  height: 10px;
  background-position: -45px -128px;
}

.s.py {
  width: 15px;
  height: 9px;
  background-position: -15px -148px;
}

.s.qa {
  width: 15px;
  height: 6px;
  background-position: -165px -151px;
}

.s.re {
  width: 15px;
  height: 10px;
  background-position: -90px -128px;
}

.s.ro {
  width: 15px;
  height: 10px;
  background-position: -105px -128px;
}

.s.rs {
  width: 15px;
  height: 10px;
  background-position: -120px -128px;
}

.s.ru {
  width: 15px;
  height: 10px;
  background-position: -135px -128px;
}

.s.rw {
  width: 15px;
  height: 10px;
  background-position: 0 -138px;
}

.s.sa {
  width: 15px;
  height: 10px;
  background-position: -15px -138px;
}

.s.sb {
  width: 15px;
  height: 8px;
  background-position: -135px -157px;
}

.s.sc {
  width: 15px;
  height: 8px;
  background-position: -150px -157px;
}

.s.sd {
  width: 15px;
  height: 8px;
  background-position: -165px -157px;
}

.s.se {
  width: 15px;
  height: 10px;
  background-position: -30px -138px;
}

.s.sg {
  width: 15px;
  height: 10px;
  background-position: -45px -138px;
}

.s.sh {
  width: 15px;
  height: 8px;
  background-position: -30px -165px;
}

.s.si {
  width: 15px;
  height: 8px;
  background-position: -45px -165px;
}

.s.sj {
  width: 15px;
  height: 11px;
  background-position: -15px -47px;
}

.s.sk {
  width: 15px;
  height: 10px;
  background-position: -75px -138px;
}

.s.sl {
  width: 15px;
  height: 10px;
  background-position: -90px -138px;
}

.s.sm {
  width: 15px;
  height: 12px;
  background-position: -15px 0;
}

.s.sn {
  width: 15px;
  height: 10px;
  background-position: -120px -138px;
}

.s.so {
  width: 15px;
  height: 10px;
  background-position: -135px -138px;
}

.s.sr {
  width: 15px;
  height: 10px;
  background-position: -150px 0;
}

.s.ss {
  width: 15px;
  height: 8px;
  background-position: -165px -165px;
}

.s.st {
  width: 15px;
  height: 8px;
  background-position: -180px 0;
}

.s.sv {
  width: 15px;
  height: 9px;
  background-position: -60px -148px;
}

.s.sx {
  width: 15px;
  height: 10px;
  background-position: -150px -20px;
}

.s.sy {
  width: 15px;
  height: 10px;
  background-position: -150px -10px;
}

.s.sz {
  width: 15px;
  height: 10px;
  background-position: -60px -128px;
}

.s.ta {
  width: 15px;
  height: 8px;
  background-position: -180px -40px;
}

.s.tc {
  width: 15px;
  height: 8px;
  background-position: -180px -48px;
}

.s.td {
  width: 15px;
  height: 10px;
  background-position: -135px -30px;
}

.s.tf {
  width: 15px;
  height: 10px;
  background-position: -120px -118px;
}

.s.tg {
  width: 15px;
  height: 10px;
  background-position: -120px -50px;
}

.s.th {
  width: 15px;
  height: 10px;
  background-position: -120px -40px;
}

.s.tj {
  width: 15px;
  height: 8px;
  background-position: -180px -88px;
}

.s.tk {
  width: 15px;
  height: 8px;
  background-position: -180px -96px;
}

.s.tl {
  width: 15px;
  height: 8px;
  background-position: -180px -104px;
}

.s.tm {
  width: 15px;
  height: 10px;
  background-position: -120px -20px;
}

.s.tn {
  width: 15px;
  height: 10px;
  background-position: -60px -108px;
}

.s.to {
  width: 15px;
  height: 8px;
  background-position: -180px -128px;
}

.s.tr {
  width: 15px;
  height: 10px;
  background-position: -30px -108px;
}

.s.tt {
  width: 15px;
  height: 9px;
  background-position: -150px -117px;
}

.s.tv {
  width: 15px;
  height: 8px;
  background-position: -180px -152px;
}

.s.tw {
  width: 15px;
  height: 10px;
  background-position: -105px -98px;
}

.s.tz {
  width: 15px;
  height: 10px;
  background-position: -60px -98px;
}

.s.ua {
  width: 15px;
  height: 10px;
  background-position: -105px -70px;
}

.s.ug {
  width: 15px;
  height: 10px;
  background-position: -105px -50px;
}

.s.um {
  width: 15px;
  height: 8px;
  background-position: -45px -173px;
}

.s.un {
  width: 15px;
  height: 10px;
  background-position: 0 -88px;
}

.s.us {
  width: 15px;
  height: 8px;
  background-position: -75px -173px;
}

.s.uy {
  width: 15px;
  height: 10px;
  background-position: -90px -40px;
}

.s.uz {
  width: 15px;
  height: 8px;
  background-position: -105px -173px;
}

.s.va {
  width: 12px;
  height: 12px;
  background-position: -150px -30px;
}

.s.vc {
  width: 15px;
  height: 10px;
  background-position: -75px -68px;
}

.s.ve {
  width: 15px;
  height: 10px;
  background-position: -15px -68px;
}

.s.vg {
  width: 15px;
  height: 8px;
  background-position: -165px -173px;
}

.s.vi {
  width: 15px;
  height: 10px;
  background-position: 0 -68px;
}

.s.vn {
  width: 15px;
  height: 10px;
  background-position: -60px -58px;
}

.s.vu {
  width: 15px;
  height: 9px;
  background-position: -135px -148px;
}

.s.wf {
  width: 15px;
  height: 10px;
  background-position: -135px -90px;
}

.s.ws {
  width: 15px;
  height: 8px;
  background-position: -45px -181px;
}

.s.xk {
  width: 15px;
  height: 11px;
  background-position: -15px -36px;
}

.s.ye {
  width: 15px;
  height: 10px;
  background-position: -135px -20px;
}

.s.yt {
  width: 15px;
  height: 10px;
  background-position: -75px -98px;
}

.s.za {
  width: 15px;
  height: 10px;
  background-position: -135px -50px;
}

.s.zm {
  width: 15px;
  height: 10px;
  background-position: -75px -128px;
}

.s.zw {
  width: 15px;
  height: 8px;
  background-position: -120px -181px;
}

/* sprite size m */

.m.ac {
  width: 20px;
  height: 10px;
  background-position: -180px -213px;
}

.m.ad {
  width: 20px;
  height: 14px;
  background-position: -160px -14px;
}

.m.ae {
  width: 20px;
  height: 10px;
  background-position: -238px -70px;
}

.m.af {
  width: 20px;
  height: 14px;
  background-position: -160px 0;
}

.m.ag {
  width: 20px;
  height: 14px;
  background-position: -140px -130px;
}

.m.ai {
  width: 20px;
  height: 10px;
  background-position: -238px -120px;
}

.m.al {
  width: 20px;
  height: 15px;
  background-position: -20px -30px;
}

.m.am {
  width: 20px;
  height: 10px;
  background-position: -238px 0;
}

.m.ao {
  width: 20px;
  height: 14px;
  background-position: -20px -74px;
}

.m.aq {
  width: 20px;
  height: 14px;
  background-position: -20px -102px;
}

.m.ar {
  width: 20px;
  height: 13px;
  background-position: 0 -200px;
}

.m.as {
  width: 20px;
  height: 10px;
  background-position: -80px -224px;
}

.m.at {
  width: 20px;
  height: 14px;
  background-position: 0 -116px;
}

.m.au {
  width: 20px;
  height: 10px;
  background-position: -238px -30px;
}

.m.aw {
  width: 20px;
  height: 14px;
  background-position: -60px -130px;
}

.m.ax {
  width: 20px;
  height: 13px;
  background-position: -100px -186px;
}

.m.az {
  width: 20px;
  height: 10px;
  background-position: -20px -234px;
}

.m.ba {
  width: 20px;
  height: 10px;
  background-position: -258px -20px;
}

.m.bb {
  width: 20px;
  height: 14px;
  background-position: -160px -70px;
}

.m.bd {
  width: 20px;
  height: 12px;
  background-position: -218px -84px;
}

.m.be {
  width: 18px;
  height: 15px;
  background-position: -200px -15px;
}

.m.bf {
  width: 20px;
  height: 14px;
  background-position: -180px -126px;
}

.m.bg {
  width: 20px;
  height: 12px;
  background-position: -218px -120px;
}

.m.bh {
  width: 20px;
  height: 12px;
  background-position: -218px -108px;
}

.m.bi {
  width: 20px;
  height: 12px;
  background-position: -218px -96px;
}

.m.bj {
  width: 20px;
  height: 14px;
  background-position: -20px -60px;
}

.m.bl {
  width: 20px;
  height: 14px;
  background-position: -40px -60px;
}

.m.bm {
  width: 20px;
  height: 10px;
  background-position: -200px -213px;
}

.m.bn {
  width: 20px;
  height: 10px;
  background-position: -20px -224px;
}

.m.bo {
  width: 20px;
  height: 14px;
  background-position: -60px -60px;
}

.m.bq {
  width: 20px;
  height: 14px;
  background-position: -80px 0;
}

.m.br {
  width: 20px;
  height: 14px;
  background-position: -80px -14px;
}

.m.bs {
  width: 20px;
  height: 10px;
  background-position: -238px -50px;
}

.m.bt {
  width: 20px;
  height: 14px;
  background-position: -80px -28px;
}

.m.bv {
  width: 20px;
  height: 15px;
  background-position: -20px -15px;
}

.m.bw {
  width: 20px;
  height: 14px;
  background-position: -80px -56px;
}

.m.by {
  width: 20px;
  height: 10px;
  background-position: -60px -244px;
}

.m.bz {
  width: 20px;
  height: 14px;
  background-position: 0 -74px;
}

.m.ca {
  width: 20px;
  height: 10px;
  background-position: -258px -30px;
}

.m.cc {
  width: 20px;
  height: 10px;
  background-position: -258px -100px;
}

.m.cd {
  width: 20px;
  height: 15px;
  background-position: -40px 0;
}

.m.cf {
  width: 20px;
  height: 14px;
  background-position: -40px -74px;
}

.m.cg {
  width: 20px;
  height: 14px;
  background-position: -60px -74px;
}

.m.ch {
  width: 15px;
  height: 15px;
  background-position: -218px -183px;
}

.m.ci {
  width: 20px;
  height: 14px;
  background-position: -100px 0;
}

.m.ck {
  width: 20px;
  height: 10px;
  background-position: -238px -20px;
}

.m.cl {
  width: 20px;
  height: 14px;
  background-position: -100px -14px;
}

.m.cm {
  width: 20px;
  height: 14px;
  background-position: -100px -28px;
}

.m.cn {
  width: 20px;
  height: 14px;
  background-position: -100px -42px;
}

.m.co {
  width: 20px;
  height: 14px;
  background-position: -100px -56px;
}

.m.cp {
  width: 20px;
  height: 14px;
  background-position: -100px -70px;
}

.m.cr {
  width: 20px;
  height: 12px;
  background-position: -218px -60px;
}

.m.cu {
  width: 20px;
  height: 10px;
  background-position: -100px -244px;
}

.m.cv {
  width: 20px;
  height: 12px;
  background-position: -218px -48px;
}

.m.cw {
  width: 20px;
  height: 14px;
  background-position: -40px -88px;
}

.m.cx {
  width: 20px;
  height: 10px;
  background-position: -258px -60px;
}

.m.cy {
  width: 20px;
  height: 14px;
  background-position: -60px -88px;
}

.m.cz {
  width: 20px;
  height: 14px;
  background-position: -80px -88px;
}

.m.de {
  width: 20px;
  height: 12px;
  background-position: -218px -156px;
}

.m.dg {
  width: 20px;
  height: 10px;
  background-position: 0 -224px;
}

.m.dj {
  width: 20px;
  height: 14px;
  background-position: 0 -102px;
}

.m.dk {
  width: 20px;
  height: 15px;
  background-position: -40px -15px;
}

.m.dm {
  width: 20px;
  height: 10px;
  background-position: -180px -224px;
}

.m.do {
  width: 20px;
  height: 14px;
  background-position: -40px -102px;
}

.m.dz {
  width: 20px;
  height: 14px;
  background-position: -60px -102px;
}

.m.ea {
  width: 20px;
  height: 14px;
  background-position: -80px -102px;
}

.m.ec {
  width: 20px;
  height: 14px;
  background-position: -100px -102px;
}

.m.ee {
  width: 20px;
  height: 13px;
  background-position: -120px -186px;
}

.m.eg {
  width: 20px;
  height: 14px;
  background-position: -120px -14px;
}

.m.eh {
  width: 20px;
  height: 10px;
  background-position: -238px -150px;
}

.m.er {
  width: 20px;
  height: 10px;
  background-position: -238px -190px;
}

.m.es {
  width: 20px;
  height: 14px;
  background-position: -120px -28px;
}

.m.et {
  width: 20px;
  height: 10px;
  background-position: -160px -234px;
}

.m.eu {
  width: 20px;
  height: 14px;
  background-position: -120px -42px;
}

.m.fi {
  width: 20px;
  height: 12px;
  background-position: -218px -12px;
}

.m.fj {
  width: 20px;
  height: 10px;
  background-position: -180px -244px;
}

.m.fk {
  width: 20px;
  height: 10px;
  background-position: -220px -244px;
}

.m.fm {
  width: 20px;
  height: 11px;
  background-position: -218px -198px;
}

.m.fo {
  width: 20px;
  height: 15px;
  background-position: 0 -30px;
}

.m.fr {
  width: 20px;
  height: 14px;
  background-position: -120px -98px;
}

.m.ga {
  width: 20px;
  height: 15px;
  background-position: 0 0;
}

.m.gb {
  width: 20px;
  height: 10px;
  background-position: -258px -110px;
}

.m.gd {
  width: 20px;
  height: 12px;
  background-position: -180px -200px;
}

.m.ge {
  width: 20px;
  height: 14px;
  background-position: -40px -116px;
}

.m.gf {
  width: 20px;
  height: 14px;
  background-position: -60px -116px;
}

.m.gg {
  width: 20px;
  height: 14px;
  background-position: -80px -116px;
}

.m.gh {
  width: 20px;
  height: 14px;
  background-position: -100px -116px;
}

.m.gi {
  width: 20px;
  height: 10px;
  background-position: -100px -224px;
}

.m.gl {
  width: 20px;
  height: 14px;
  background-position: -120px -116px;
}

.m.gm {
  width: 20px;
  height: 14px;
  background-position: -140px 0;
}

.m.gn {
  width: 20px;
  height: 14px;
  background-position: -140px -14px;
}

.m.gp {
  width: 20px;
  height: 14px;
  background-position: -140px -28px;
}

.m.gq {
  width: 20px;
  height: 14px;
  background-position: -140px -42px;
}

.m.gr {
  width: 20px;
  height: 14px;
  background-position: -140px -56px;
}

.m.gs {
  width: 20px;
  height: 10px;
  background-position: -238px -110px;
}

.m.gt {
  width: 20px;
  height: 13px;
  background-position: -60px -186px;
}

.m.gu {
  width: 20px;
  height: 11px;
  background-position: -60px -213px;
}

.m.gw {
  width: 20px;
  height: 10px;
  background-position: -238px -180px;
}

.m.gy {
  width: 20px;
  height: 12px;
  background-position: -120px -200px;
}

.m.hk {
  width: 20px;
  height: 14px;
  background-position: -140px -112px;
}

.m.hm {
  width: 20px;
  height: 10px;
  background-position: -40px -234px;
}

.m.hn {
  width: 20px;
  height: 10px;
  background-position: -60px -234px;
}

.m.hr {
  width: 20px;
  height: 10px;
  background-position: -140px -234px;
}

.m.ht {
  width: 20px;
  height: 12px;
  background-position: -100px -200px;
}

.m.hu {
  width: 20px;
  height: 10px;
  background-position: -200px -234px;
}

.m.ic {
  width: 20px;
  height: 14px;
  background-position: -20px -130px;
}

.m.id {
  width: 20px;
  height: 14px;
  background-position: -40px -130px;
}

.m.ie {
  width: 20px;
  height: 10px;
  background-position: -160px -244px;
}

.m.il {
  width: 20px;
  height: 15px;
  background-position: -40px -30px;
}

.m.im {
  width: 20px;
  height: 10px;
  background-position: -200px -244px;
}

.m.in {
  width: 20px;
  height: 14px;
  background-position: -80px -130px;
}

.m.io {
  width: 20px;
  height: 10px;
  background-position: -258px 0;
}

.m.iq {
  width: 20px;
  height: 14px;
  background-position: -100px -130px;
}

.m.ir {
  width: 20px;
  height: 12px;
  background-position: -80px -200px;
}

.m.is {
  width: 20px;
  height: 15px;
  background-position: 0 -45px;
}

.m.it {
  width: 20px;
  height: 14px;
  background-position: 0 -144px;
}

.m.je {
  width: 20px;
  height: 12px;
  background-position: -60px -200px;
}

.m.jm {
  width: 20px;
  height: 10px;
  background-position: -258px -120px;
}

.m.jo {
  width: 20px;
  height: 10px;
  background-position: -258px -140px;
}

.m.jp {
  width: 20px;
  height: 14px;
  background-position: -40px -144px;
}

.m.ke {
  width: 20px;
  height: 14px;
  background-position: -60px -144px;
}

.m.kg {
  width: 20px;
  height: 12px;
  background-position: -40px -200px;
}

.m.kh {
  width: 20px;
  height: 13px;
  background-position: -180px -186px;
}

.m.ki {
  width: 20px;
  height: 10px;
  background-position: -60px -224px;
}

.m.km {
  width: 20px;
  height: 12px;
  background-position: -20px -200px;
}

.m.kn {
  width: 20px;
  height: 14px;
  background-position: -140px -144px;
}

.m.kp {
  width: 20px;
  height: 10px;
  background-position: -140px -224px;
}

.m.kr {
  width: 20px;
  height: 14px;
  background-position: -60px -30px;
}

.m.kw {
  width: 20px;
  height: 10px;
  background-position: -200px -224px;
}

.m.ky {
  width: 20px;
  height: 10px;
  background-position: -120px -213px;
}

.m.kz {
  width: 20px;
  height: 10px;
  background-position: -238px -10px;
}

.m.la {
  width: 20px;
  height: 14px;
  background-position: -160px -28px;
}

.m.lb {
  width: 20px;
  height: 14px;
  background-position: -160px -42px;
}

.m.lc {
  width: 20px;
  height: 10px;
  background-position: -238px -40px;
}

.m.li {
  width: 20px;
  height: 12px;
  background-position: -140px -200px;
}

.m.lk {
  width: 20px;
  height: 10px;
  background-position: -238px -60px;
}

.m.lr {
  width: 20px;
  height: 11px;
  background-position: -100px -213px;
}

.m.ls {
  width: 20px;
  height: 14px;
  background-position: -160px -84px;
}

.m.lt {
  width: 20px;
  height: 12px;
  background-position: -160px -200px;
}

.m.lu {
  width: 20px;
  height: 12px;
  background-position: -218px -24px;
}

.m.lv {
  width: 20px;
  height: 10px;
  background-position: -238px -160px;
}

.m.ly {
  width: 20px;
  height: 10px;
  background-position: -238px -170px;
}

.m.ma {
  width: 20px;
  height: 14px;
  background-position: -160px -126px;
}

.m.mc {
  width: 19px;
  height: 15px;
  background-position: -60px -15px;
}

.m.md {
  width: 20px;
  height: 10px;
  background-position: -238px -200px;
}

.m.me {
  width: 20px;
  height: 10px;
  background-position: -238px -210px;
}

.m.mf {
  width: 20px;
  height: 14px;
  background-position: 0 -158px;
}

.m.mg {
  width: 20px;
  height: 14px;
  background-position: -20px -158px;
}

.m.mh {
  width: 20px;
  height: 11px;
  background-position: -80px -213px;
}

.m.mk {
  width: 20px;
  height: 10px;
  background-position: -80px -234px;
}

.m.ml {
  width: 20px;
  height: 14px;
  background-position: -60px -158px;
}

.m.mm {
  width: 20px;
  height: 14px;
  background-position: -80px -158px;
}

.m.mn {
  width: 20px;
  height: 10px;
  background-position: -180px -234px;
}

.m.mo {
  width: 20px;
  height: 14px;
  background-position: -100px -158px;
}

.m.mp {
  width: 20px;
  height: 10px;
  background-position: -40px -244px;
}

.m.mq {
  width: 20px;
  height: 14px;
  background-position: -120px -158px;
}

.m.mr {
  width: 20px;
  height: 14px;
  background-position: -140px -158px;
}

.m.ms {
  width: 20px;
  height: 10px;
  background-position: -120px -244px;
}

.m.mt {
  width: 20px;
  height: 14px;
  background-position: -160px -158px;
}

.m.mu {
  width: 20px;
  height: 14px;
  background-position: -180px 0;
}

.m.mv {
  width: 20px;
  height: 14px;
  background-position: -180px -14px;
}

.m.mw {
  width: 20px;
  height: 14px;
  background-position: -180px -28px;
}

.m.mx {
  width: 20px;
  height: 12px;
  background-position: -218px -132px;
}

.m.my {
  width: 20px;
  height: 10px;
  background-position: -258px -10px;
}

.m.mz {
  width: 20px;
  height: 14px;
  background-position: -180px -56px;
}

.m.na {
  width: 20px;
  height: 14px;
  background-position: -180px -70px;
}

.m.nc {
  width: 20px;
  height: 10px;
  background-position: -258px -50px;
}

.m.ne {
  width: 18px;
  height: 15px;
  background-position: -200px 0;
}

.m.nf {
  width: 20px;
  height: 10px;
  background-position: -258px -70px;
}

.m.ng {
  width: 20px;
  height: 10px;
  background-position: -258px -90px;
}

.m.ni {
  width: 20px;
  height: 12px;
  background-position: -218px -72px;
}

.m.nl {
  width: 20px;
  height: 14px;
  background-position: -180px -112px;
}

.m.no {
  width: 20px;
  height: 15px;
  background-position: -40px -45px;
}

.m.np {
  width: 13px;
  height: 15px;
  background-position: -258px -180px;
}

.m.nr {
  width: 20px;
  height: 10px;
  background-position: -258px -150px;
}

.m.nu {
  width: 20px;
  height: 10px;
  background-position: -258px -160px;
}

.m.nz {
  width: 20px;
  height: 10px;
  background-position: -140px -213px;
}

.m.om {
  width: 20px;
  height: 10px;
  background-position: -160px -213px;
}

.m.pa {
  width: 20px;
  height: 14px;
  background-position: -180px -154px;
}

.m.pe {
  width: 20px;
  height: 14px;
  background-position: 0 -172px;
}

.m.pf {
  width: 20px;
  height: 14px;
  background-position: -20px -172px;
}

.m.pg {
  width: 20px;
  height: 15px;
  background-position: -20px 0;
}

.m.ph {
  width: 20px;
  height: 10px;
  background-position: -40px -224px;
}

.m.pk {
  width: 20px;
  height: 14px;
  background-position: -60px -172px;
}

.m.pl {
  width: 20px;
  height: 13px;
  background-position: -140px -186px;
}

.m.pm {
  width: 20px;
  height: 14px;
  background-position: -100px -172px;
}

.m.pn {
  width: 20px;
  height: 10px;
  background-position: -120px -224px;
}

.m.pr {
  width: 20px;
  height: 14px;
  background-position: -120px -172px;
}

.m.ps {
  width: 20px;
  height: 10px;
  background-position: -160px -224px;
}

.m.pt {
  width: 20px;
  height: 14px;
  background-position: -140px -172px;
}

.m.pw {
  width: 20px;
  height: 13px;
  background-position: -40px -186px;
}

.m.py {
  width: 20px;
  height: 11px;
  background-position: -20px -213px;
}

.m.qa {
  width: 20px;
  height: 8px;
  background-position: -258px -195px;
}

.m.re {
  width: 20px;
  height: 14px;
  background-position: -20px -186px;
}

.m.ro {
  width: 20px;
  height: 14px;
  background-position: -180px -172px;
}

.m.rs {
  width: 20px;
  height: 14px;
  background-position: -160px -172px;
}

.m.ru {
  width: 20px;
  height: 14px;
  background-position: -80px -172px;
}

.m.rw {
  width: 20px;
  height: 14px;
  background-position: -180px -98px;
}

.m.sa {
  width: 20px;
  height: 14px;
  background-position: -180px -84px;
}

.m.sb {
  width: 20px;
  height: 10px;
  background-position: -238px -80px;
}

.m.sc {
  width: 20px;
  height: 10px;
  background-position: -238px -90px;
}

.m.sd {
  width: 20px;
  height: 10px;
  background-position: -238px -100px;
}

.m.se {
  width: 20px;
  height: 13px;
  background-position: -80px -186px;
}

.m.sg {
  width: 20px;
  height: 14px;
  background-position: -180px -42px;
}

.m.sh {
  width: 20px;
  height: 10px;
  background-position: -238px -130px;
}

.m.si {
  width: 20px;
  height: 10px;
  background-position: -238px -140px;
}

.m.sj {
  width: 20px;
  height: 15px;
  background-position: -60px 0;
}

.m.sk {
  width: 20px;
  height: 14px;
  background-position: -160px -112px;
}

.m.sl {
  width: 20px;
  height: 14px;
  background-position: -160px -98px;
}

.m.sm {
  width: 20px;
  height: 15px;
  background-position: -20px -45px;
}

.m.sn {
  width: 20px;
  height: 14px;
  background-position: -160px -56px;
}

.m.so {
  width: 20px;
  height: 14px;
  background-position: -120px -144px;
}

.m.sr {
  width: 20px;
  height: 14px;
  background-position: -100px -144px;
}

.m.ss {
  width: 20px;
  height: 10px;
  background-position: -238px -220px;
}

.m.st {
  width: 20px;
  height: 10px;
  background-position: 0 -234px;
}

.m.sv {
  width: 20px;
  height: 12px;
  background-position: -218px -36px;
}

.m.sx {
  width: 20px;
  height: 14px;
  background-position: -80px -144px;
}

.m.sy {
  width: 20px;
  height: 14px;
  background-position: -20px -144px;
}

.m.sz {
  width: 20px;
  height: 14px;
  background-position: -120px -130px;
}

.m.ta {
  width: 20px;
  height: 10px;
  background-position: -100px -234px;
}

.m.tc {
  width: 20px;
  height: 10px;
  background-position: -120px -234px;
}

.m.td {
  width: 20px;
  height: 14px;
  background-position: 0 -130px;
}

.m.tf {
  width: 20px;
  height: 14px;
  background-position: -140px -98px;
}

.m.tg {
  width: 20px;
  height: 13px;
  background-position: -160px -186px;
}

.m.th {
  width: 20px;
  height: 14px;
  background-position: -140px -84px;
}

.m.tj {
  width: 20px;
  height: 10px;
  background-position: -220px -234px;
}

.m.tk {
  width: 20px;
  height: 10px;
  background-position: 0 -244px;
}

.m.tl {
  width: 20px;
  height: 10px;
  background-position: -20px -244px;
}

.m.tm {
  width: 20px;
  height: 14px;
  background-position: -140px -70px;
}

.m.tn {
  width: 20px;
  height: 14px;
  background-position: -20px -116px;
}

.m.to {
  width: 20px;
  height: 10px;
  background-position: -80px -244px;
}

.m.tr {
  width: 20px;
  height: 14px;
  background-position: -120px -70px;
}

.m.tt {
  width: 20px;
  height: 12px;
  background-position: -218px 0;
}

.m.tv {
  width: 20px;
  height: 10px;
  background-position: -140px -244px;
}

.m.tw {
  width: 20px;
  height: 14px;
  background-position: -120px -56px;
}

.m.tz {
  width: 20px;
  height: 14px;
  background-position: -120px 0;
}

.m.ua {
  width: 20px;
  height: 14px;
  background-position: -100px -88px;
}

.m.ug {
  width: 20px;
  height: 14px;
  background-position: -20px -88px;
}

.m.um {
  width: 20px;
  height: 11px;
  background-position: 0 -213px;
}

.m.un {
  width: 20px;
  height: 14px;
  background-position: 0 -88px;
}

.m.us {
  width: 20px;
  height: 11px;
  background-position: -40px -213px;
}

.m.uy {
  width: 20px;
  height: 14px;
  background-position: -80px -74px;
}

.m.uz {
  width: 20px;
  height: 10px;
  background-position: -258px -40px;
}

.m.va {
  width: 15px;
  height: 15px;
  background-position: -218px -168px;
}

.m.vc {
  width: 20px;
  height: 14px;
  background-position: 0 -60px;
}

.m.ve {
  width: 20px;
  height: 14px;
  background-position: -60px -44px;
}

.m.vg {
  width: 20px;
  height: 10px;
  background-position: -258px -80px;
}

.m.vi {
  width: 20px;
  height: 14px;
  background-position: -40px -172px;
}

.m.vn {
  width: 20px;
  height: 14px;
  background-position: -40px -158px;
}

.m.vu {
  width: 20px;
  height: 12px;
  background-position: -218px -144px;
}

.m.wf {
  width: 20px;
  height: 14px;
  background-position: -160px -140px;
}

.m.ws {
  width: 20px;
  height: 10px;
  background-position: -258px -130px;
}

.m.xk {
  width: 20px;
  height: 15px;
  background-position: 0 -15px;
}

.m.ye {
  width: 20px;
  height: 14px;
  background-position: -120px -84px;
}

.m.yt {
  width: 20px;
  height: 14px;
  background-position: -80px -42px;
}

.m.za {
  width: 20px;
  height: 14px;
  background-position: 0 -186px;
}

.m.zm {
  width: 20px;
  height: 14px;
  background-position: -180px -140px;
}

.m.zw {
  width: 20px;
  height: 10px;
  background-position: -258px -170px;
}

/* sprite size l/xl */

.l.ac,
.xl.ac {
  width: 22px;
  height: 11px;
  background-position: -242px -143px;
}

.l.ad,
.xl.ad {
  width: 22px;
  height: 16px;
  background-position: 0 -34px;
}

.l.ae,
.xl.ae {
  width: 22px;
  height: 11px;
  background-position: -242px -231px;
}

.l.af,
.xl.af {
  width: 22px;
  height: 15px;
  background-position: -176px 0;
}

.l.ag,
.xl.ag {
  width: 22px;
  height: 15px;
  background-position: -154px -143px;
}

.l.ai,
.xl.ai {
  width: 22px;
  height: 11px;
  background-position: -88px -242px;
}

.l.al,
.xl.al {
  width: 22px;
  height: 16px;
  background-position: 0 -50px;
}

.l.am,
.xl.am {
  width: 22px;
  height: 11px;
  background-position: -88px -231px;
}

.l.ao,
.xl.ao {
  width: 22px;
  height: 15px;
  background-position: -88px -15px;
}

.l.aq,
.xl.aq {
  width: 22px;
  height: 15px;
  background-position: -88px -45px;
}

.l.ar,
.xl.ar {
  width: 22px;
  height: 14px;
  background-position: -132px -203px;
}

.l.as,
.xl.as {
  width: 22px;
  height: 11px;
  background-position: -242px -209px;
}

.l.at,
.xl.at {
  width: 22px;
  height: 15px;
  background-position: -22px -113px;
}

.l.au,
.xl.au {
  width: 22px;
  height: 11px;
  background-position: -154px -231px;
}

.l.aw,
.xl.aw {
  width: 22px;
  height: 15px;
  background-position: -132px -90px;
}

.l.ax,
.xl.ax {
  width: 22px;
  height: 15px;
  background-position: 0 -128px;
}

.l.az,
.xl.az {
  width: 22px;
  height: 11px;
  background-position: -88px -253px;
}

.l.ba,
.xl.ba {
  width: 22px;
  height: 11px;
  background-position: -264px -187px;
}

.l.bb,
.xl.bb {
  width: 22px;
  height: 15px;
  background-position: -66px -143px;
}

.l.bd,
.xl.bd {
  width: 22px;
  height: 13px;
  background-position: -242px -13px;
}

.l.be,
.xl.be {
  width: 20px;
  height: 17px;
  background-position: -20px -66px;
}

.l.bf,
.xl.bf {
  width: 22px;
  height: 15px;
  background-position: -176px -120px;
}

.l.bg,
.xl.bg {
  width: 22px;
  height: 13px;
  background-position: -242px 0;
}

.l.bh,
.xl.bh {
  width: 22px;
  height: 13px;
  background-position: -198px -218px;
}

.l.bi,
.xl.bi {
  width: 22px;
  height: 13px;
  background-position: -176px -218px;
}

.l.bj,
.xl.bj {
  width: 22px;
  height: 15px;
  background-position: -198px -135px;
}

.l.bl,
.xl.bl {
  width: 22px;
  height: 15px;
  background-position: -44px -188px;
}

.l.bm,
.xl.bm {
  width: 22px;
  height: 11px;
  background-position: -242px -154px;
}

.l.bn,
.xl.bn {
  width: 22px;
  height: 11px;
  background-position: -242px -176px;
}

.l.bo,
.xl.bo {
  width: 22px;
  height: 15px;
  background-position: -62px -66px;
}

.l.bq,
.xl.bq {
  width: 22px;
  height: 15px;
  background-position: -88px 0;
}

.l.br,
.xl.br {
  width: 22px;
  height: 16px;
  background-position: -66px 0;
}

.l.bs,
.xl.bs {
  width: 22px;
  height: 11px;
  background-position: -198px -231px;
}

.l.bt,
.xl.bt {
  width: 22px;
  height: 15px;
  background-position: -88px -30px;
}

.l.bv,
.xl.bv {
  width: 22px;
  height: 16px;
  background-position: -22px -34px;
}

.l.bw,
.xl.bw {
  width: 22px;
  height: 15px;
  background-position: -88px -60px;
}

.l.by,
.xl.by {
  width: 22px;
  height: 11px;
  background-position: -264px -66px;
}

.l.bz,
.xl.bz {
  width: 22px;
  height: 15px;
  background-position: 0 -83px;
}

.l.ca,
.xl.ca {
  width: 22px;
  height: 11px;
  background-position: -264px -198px;
}

.l.cc,
.xl.cc {
  width: 22px;
  height: 11px;
  background-position: -22px -264px;
}

.l.cd,
.xl.cd {
  width: 22px;
  height: 17px;
  background-position: -44px -17px;
}

.l.cf,
.xl.cf {
  width: 22px;
  height: 15px;
  background-position: -44px -83px;
}

.l.cg,
.xl.cg {
  width: 22px;
  height: 15px;
  background-position: -66px -83px;
}

.l.ch,
.xl.ch {
  width: 17px;
  height: 17px;
  background-position: -220px -73px;
}

.l.ci,
.xl.ci {
  width: 22px;
  height: 15px;
  background-position: -110px 0;
}

.l.ck,
.xl.ck {
  width: 22px;
  height: 11px;
  background-position: -132px -231px;
}

.l.cl,
.xl.cl {
  width: 22px;
  height: 15px;
  background-position: -110px -15px;
}

.l.cm,
.xl.cm {
  width: 22px;
  height: 15px;
  background-position: -110px -30px;
}

.l.cn,
.xl.cn {
  width: 22px;
  height: 15px;
  background-position: -110px -45px;
}

.l.co,
.xl.co {
  width: 22px;
  height: 15px;
  background-position: -110px -60px;
}

.l.cp,
.xl.cp {
  width: 22px;
  height: 15px;
  background-position: -110px -75px;
}

.l.cr,
.xl.cr {
  width: 22px;
  height: 13px;
  background-position: -132px -218px;
}

.l.cu,
.xl.cu {
  width: 22px;
  height: 11px;
  background-position: -264px -88px;
}

.l.cv,
.xl.cv {
  width: 22px;
  height: 13px;
  background-position: -88px -218px;
}

.l.cw,
.xl.cw {
  width: 22px;
  height: 15px;
  background-position: -44px -98px;
}

.l.cx,
.xl.cx {
  width: 22px;
  height: 11px;
  background-position: -264px -231px;
}

.l.cy,
.xl.cy {
  width: 22px;
  height: 15px;
  background-position: -66px -98px;
}

.l.cz,
.xl.cz {
  width: 22px;
  height: 15px;
  background-position: -88px -98px;
}

.l.de,
.xl.de {
  width: 22px;
  height: 13px;
  background-position: -66px -218px;
}

.l.dg,
.xl.dg {
  width: 22px;
  height: 11px;
  background-position: -242px -165px;
}

.l.dj,
.xl.dj {
  width: 22px;
  height: 15px;
  background-position: 0 -113px;
}

.l.dk,
.xl.dk {
  width: 22px;
  height: 17px;
  background-position: -44px 0;
}

.l.dm,
.xl.dm {
  width: 22px;
  height: 11px;
  background-position: -44px -231px;
}

.l.do,
.xl.do {
  width: 22px;
  height: 15px;
  background-position: -44px -113px;
}

.l.dz,
.xl.dz {
  width: 22px;
  height: 15px;
  background-position: -66px -113px;
}

.l.ea,
.xl.ea {
  width: 22px;
  height: 15px;
  background-position: -88px -113px;
}

.l.ec,
.xl.ec {
  width: 22px;
  height: 15px;
  background-position: -110px -113px;
}

.l.ee,
.xl.ee {
  width: 22px;
  height: 14px;
  background-position: -176px -203px;
}

.l.eg,
.xl.eg {
  width: 22px;
  height: 15px;
  background-position: -132px -15px;
}

.l.eh,
.xl.eh {
  width: 22px;
  height: 11px;
  background-position: -154px -242px;
}

.l.er,
.xl.er {
  width: 22px;
  height: 11px;
  background-position: -242px -242px;
}

.l.es,
.xl.es {
  width: 22px;
  height: 15px;
  background-position: -132px -30px;
}

.l.et,
.xl.et {
  width: 22px;
  height: 11px;
  background-position: -242px -253px;
}

.l.eu,
.xl.eu {
  width: 22px;
  height: 15px;
  background-position: -132px -45px;
}

.l.fi,
.xl.fi {
  width: 22px;
  height: 14px;
  background-position: -198px -203px;
}

.l.fj,
.xl.fj {
  width: 22px;
  height: 11px;
  background-position: -264px -132px;
}

.l.fk,
.xl.fk {
  width: 22px;
  height: 11px;
  background-position: -264px -154px;
}

.l.fm,
.xl.fm {
  width: 22px;
  height: 12px;
  background-position: -242px -62px;
}

.l.fo,
.xl.fo {
  width: 22px;
  height: 16px;
  background-position: -44px -34px;
}

.l.fr,
.xl.fr {
  width: 22px;
  height: 15px;
  background-position: -132px -105px;
}

.l.ga,
.xl.ga {
  width: 22px;
  height: 17px;
  background-position: 0 0;
}

.l.gb,
.xl.gb {
  width: 22px;
  height: 11px;
  background-position: -44px -264px;
}

.l.gd,
.xl.gd {
  width: 22px;
  height: 13px;
  background-position: 0 -218px;
}

.l.ge,
.xl.ge {
  width: 22px;
  height: 15px;
  background-position: -44px -128px;
}

.l.gf,
.xl.gf {
  width: 22px;
  height: 15px;
  background-position: -66px -128px;
}

.l.gg,
.xl.gg {
  width: 22px;
  height: 15px;
  background-position: -88px -128px;
}

.l.gh,
.xl.gh {
  width: 22px;
  height: 15px;
  background-position: -110px -128px;
}

.l.gi,
.xl.gi {
  width: 22px;
  height: 11px;
  background-position: -242px -220px;
}

.l.gl,
.xl.gl {
  width: 22px;
  height: 15px;
  background-position: -132px -128px;
}

.l.gm,
.xl.gm {
  width: 22px;
  height: 15px;
  background-position: -154px 0;
}

.l.gn,
.xl.gn {
  width: 22px;
  height: 15px;
  background-position: -154px -15px;
}

.l.gp,
.xl.gp {
  width: 22px;
  height: 15px;
  background-position: -154px -30px;
}

.l.gq,
.xl.gq {
  width: 22px;
  height: 15px;
  background-position: -154px -45px;
}

.l.gr,
.xl.gr {
  width: 22px;
  height: 15px;
  background-position: -154px -60px;
}

.l.gs,
.xl.gs {
  width: 22px;
  height: 11px;
  background-position: -66px -242px;
}

.l.gt,
.xl.gt {
  width: 22px;
  height: 14px;
  background-position: -220px 0;
}

.l.gu,
.xl.gu {
  width: 22px;
  height: 12px;
  background-position: -242px -26px;
}

.l.gw,
.xl.gw {
  width: 22px;
  height: 11px;
  background-position: -220px -242px;
}

.l.gy,
.xl.gy {
  width: 22px;
  height: 13px;
  background-position: -220px -155px;
}

.l.hk,
.xl.hk {
  width: 22px;
  height: 15px;
  background-position: -154px -120px;
}

.l.hm,
.xl.hm {
  width: 22px;
  height: 11px;
  background-position: -110px -253px;
}

.l.hn,
.xl.hn {
  width: 22px;
  height: 11px;
  background-position: -132px -253px;
}

.l.hr,
.xl.hr {
  width: 22px;
  height: 11px;
  background-position: -220px -253px;
}

.l.ht,
.xl.ht {
  width: 22px;
  height: 13px;
  background-position: -220px -142px;
}

.l.hu,
.xl.hu {
  width: 22px;
  height: 11px;
  background-position: -264px -11px;
}

.l.ic,
.xl.ic {
  width: 22px;
  height: 15px;
  background-position: -22px -143px;
}

.l.id,
.xl.id {
  width: 22px;
  height: 15px;
  background-position: -44px -143px;
}

.l.ie,
.xl.ie {
  width: 22px;
  height: 11px;
  background-position: -264px -121px;
}

.l.il,
.xl.il {
  width: 22px;
  height: 16px;
  background-position: -22px -50px;
}

.l.im,
.xl.im {
  width: 22px;
  height: 11px;
  background-position: -264px -143px;
}

.l.in,
.xl.in {
  width: 22px;
  height: 15px;
  background-position: -88px -143px;
}

.l.io,
.xl.io {
  width: 22px;
  height: 11px;
  background-position: -264px -165px;
}

.l.iq,
.xl.iq {
  width: 22px;
  height: 15px;
  background-position: -110px -143px;
}

.l.ir,
.xl.ir {
  width: 22px;
  height: 13px;
  background-position: -220px -116px;
}

.l.is,
.xl.is {
  width: 22px;
  height: 16px;
  background-position: -44px -50px;
}

.l.it,
.xl.it {
  width: 22px;
  height: 15px;
  background-position: 0 -158px;
}

.l.je,
.xl.je {
  width: 22px;
  height: 13px;
  background-position: -220px -103px;
}

.l.jm,
.xl.jm {
  width: 22px;
  height: 11px;
  background-position: -66px -264px;
}

.l.jo,
.xl.jo {
  width: 22px;
  height: 11px;
  background-position: -110px -264px;
}

.l.jp,
.xl.jp {
  width: 22px;
  height: 15px;
  background-position: -44px -158px;
}

.l.ke,
.xl.ke {
  width: 22px;
  height: 15px;
  background-position: -66px -158px;
}

.l.kg,
.xl.kg {
  width: 22px;
  height: 13px;
  background-position: -154px -218px;
}

.l.kh,
.xl.kh {
  width: 22px;
  height: 14px;
  background-position: -220px -42px;
}

.l.ki,
.xl.ki {
  width: 22px;
  height: 11px;
  background-position: -242px -198px;
}

.l.km,
.xl.km {
  width: 22px;
  height: 13px;
  background-position: -110px -218px;
}

.l.kn,
.xl.kn {
  width: 22px;
  height: 15px;
  background-position: -154px -158px;
}

.l.kp,
.xl.kp {
  width: 22px;
  height: 11px;
  background-position: 0 -231px;
}

.l.kr,
.xl.kr {
  width: 22px;
  height: 15px;
  background-position: -40px -66px;
}

.l.kw,
.xl.kw {
  width: 22px;
  height: 11px;
  background-position: -66px -231px;
}

.l.ky,
.xl.ky {
  width: 22px;
  height: 11px;
  background-position: -242px -110px;
}

.l.kz,
.xl.kz {
  width: 22px;
  height: 11px;
  background-position: -110px -231px;
}

.l.la,
.xl.la {
  width: 22px;
  height: 15px;
  background-position: -176px -30px;
}

.l.lb,
.xl.lb {
  width: 22px;
  height: 15px;
  background-position: -176px -45px;
}

.l.lc,
.xl.lc {
  width: 22px;
  height: 11px;
  background-position: -176px -231px;
}

.l.li,
.xl.li {
  width: 22px;
  height: 13px;
  background-position: -220px -129px;
}

.l.lk,
.xl.lk {
  width: 22px;
  height: 11px;
  background-position: -220px -231px;
}

.l.lr,
.xl.lr {
  width: 22px;
  height: 12px;
  background-position: -242px -74px;
}

.l.ls,
.xl.ls {
  width: 22px;
  height: 15px;
  background-position: -176px -90px;
}

.l.lt,
.xl.lt {
  width: 22px;
  height: 13px;
  background-position: -220px -168px;
}

.l.lu,
.xl.lu {
  width: 22px;
  height: 13px;
  background-position: -220px -181px;
}

.l.lv,
.xl.lv {
  width: 22px;
  height: 11px;
  background-position: -176px -242px;
}

.l.ly,
.xl.ly {
  width: 22px;
  height: 11px;
  background-position: -198px -242px;
}

.l.ma,
.xl.ma {
  width: 22px;
  height: 15px;
  background-position: -176px -135px;
}

.l.mc,
.xl.mc {
  width: 22px;
  height: 17px;
  background-position: 0 -17px;
}

.l.md,
.xl.md {
  width: 22px;
  height: 11px;
  background-position: 0 -253px;
}

.l.me,
.xl.me {
  width: 22px;
  height: 11px;
  background-position: -22px -253px;
}

.l.mf,
.xl.mf {
  width: 22px;
  height: 15px;
  background-position: 0 -173px;
}

.l.mg,
.xl.mg {
  width: 22px;
  height: 15px;
  background-position: -22px -173px;
}

.l.mh,
.xl.mh {
  width: 22px;
  height: 12px;
  background-position: -242px -38px;
}

.l.mk,
.xl.mk {
  width: 22px;
  height: 11px;
  background-position: -154px -253px;
}

.l.ml,
.xl.ml {
  width: 22px;
  height: 15px;
  background-position: -66px -173px;
}

.l.mm,
.xl.mm {
  width: 22px;
  height: 15px;
  background-position: -88px -173px;
}

.l.mn,
.xl.mn {
  width: 22px;
  height: 11px;
  background-position: -264px 0;
}

.l.mo,
.xl.mo {
  width: 22px;
  height: 15px;
  background-position: -110px -173px;
}

.l.mp,
.xl.mp {
  width: 22px;
  height: 11px;
  background-position: -264px -55px;
}

.l.mq,
.xl.mq {
  width: 22px;
  height: 15px;
  background-position: -132px -173px;
}

.l.mr,
.xl.mr {
  width: 22px;
  height: 15px;
  background-position: -154px -173px;
}

.l.ms,
.xl.ms {
  width: 22px;
  height: 11px;
  background-position: -264px -99px;
}

.l.mt,
.xl.mt {
  width: 22px;
  height: 15px;
  background-position: -176px -173px;
}

.l.mu,
.xl.mu {
  width: 22px;
  height: 15px;
  background-position: -198px 0;
}

.l.mv,
.xl.mv {
  width: 22px;
  height: 15px;
  background-position: -198px -15px;
}

.l.mw,
.xl.mw {
  width: 22px;
  height: 15px;
  background-position: -198px -30px;
}

.l.mx,
.xl.mx {
  width: 22px;
  height: 13px;
  background-position: -220px -90px;
}

.l.my,
.xl.my {
  width: 22px;
  height: 11px;
  background-position: -264px -176px;
}

.l.mz,
.xl.mz {
  width: 22px;
  height: 15px;
  background-position: -198px -60px;
}

.l.na,
.xl.na {
  width: 22px;
  height: 15px;
  background-position: -198px -75px;
}

.l.nc,
.xl.nc {
  width: 22px;
  height: 11px;
  background-position: -264px -220px;
}

.l.ne,
.xl.ne {
  width: 20px;
  height: 17px;
  background-position: 0 -66px;
}

.l.nf,
.xl.nf {
  width: 22px;
  height: 11px;
  background-position: -264px -242px;
}

.l.ng,
.xl.ng {
  width: 22px;
  height: 11px;
  background-position: 0 -264px;
}

.l.ni,
.xl.ni {
  width: 22px;
  height: 13px;
  background-position: -44px -218px;
}

.l.nl,
.xl.nl {
  width: 22px;
  height: 15px;
  background-position: -198px -120px;
}

.l.no,
.xl.no {
  width: 22px;
  height: 16px;
  background-position: -66px -16px;
}

.l.np,
.xl.np {
  width: 14px;
  height: 17px;
  background-position: -286px 0;
}

.l.nr,
.xl.nr {
  width: 22px;
  height: 11px;
  background-position: -132px -264px;
}

.l.nu,
.xl.nu {
  width: 22px;
  height: 11px;
  background-position: -154px -264px;
}

.l.nz,
.xl.nz {
  width: 22px;
  height: 11px;
  background-position: -242px -121px;
}

.l.om,
.xl.om {
  width: 22px;
  height: 11px;
  background-position: -242px -132px;
}

.l.pa,
.xl.pa {
  width: 22px;
  height: 15px;
  background-position: -198px -165px;
}

.l.pe,
.xl.pe {
  width: 22px;
  height: 15px;
  background-position: 0 -188px;
}

.l.pf,
.xl.pf {
  width: 22px;
  height: 15px;
  background-position: -22px -188px;
}

.l.pg,
.xl.pg {
  width: 22px;
  height: 17px;
  background-position: -22px 0;
}

.l.ph,
.xl.ph {
  width: 22px;
  height: 11px;
  background-position: -242px -187px;
}

.l.pk,
.xl.pk {
  width: 22px;
  height: 15px;
  background-position: -66px -188px;
}

.l.pl,
.xl.pl {
  width: 22px;
  height: 14px;
  background-position: -154px -203px;
}

.l.pm,
.xl.pm {
  width: 22px;
  height: 15px;
  background-position: -110px -188px;
}

.l.pn,
.xl.pn {
  width: 22px;
  height: 11px;
  background-position: -220px -207px;
}

.l.pr,
.xl.pr {
  width: 22px;
  height: 15px;
  background-position: -132px -188px;
}

.l.ps,
.xl.ps {
  width: 22px;
  height: 11px;
  background-position: -22px -231px;
}

.l.pt,
.xl.pt {
  width: 22px;
  height: 15px;
  background-position: -154px -188px;
}

.l.pw,
.xl.pw {
  width: 22px;
  height: 14px;
  background-position: -110px -203px;
}

.l.py,
.xl.py {
  width: 22px;
  height: 12px;
  background-position: -242px -86px;
}

.l.qa,
.xl.qa {
  width: 22px;
  height: 9px;
  background-position: -198px -264px;
}

.l.re,
.xl.re {
  width: 22px;
  height: 15px;
  background-position: -22px -203px;
}

.l.ro,
.xl.ro {
  width: 22px;
  height: 15px;
  background-position: -44px -203px;
}

.l.rs,
.xl.rs {
  width: 22px;
  height: 15px;
  background-position: -66px -203px;
}

.l.ru,
.xl.ru {
  width: 22px;
  height: 15px;
  background-position: -88px -203px;
}

.l.rw,
.xl.rw {
  width: 22px;
  height: 15px;
  background-position: -198px -188px;
}

.l.sa,
.xl.sa {
  width: 22px;
  height: 15px;
  background-position: -176px -188px;
}

.l.sb,
.xl.sb {
  width: 22px;
  height: 11px;
  background-position: 0 -242px;
}

.l.sc,
.xl.sc {
  width: 22px;
  height: 11px;
  background-position: -22px -242px;
}

.l.sd,
.xl.sd {
  width: 22px;
  height: 11px;
  background-position: -44px -242px;
}

.l.se,
.xl.se {
  width: 22px;
  height: 14px;
  background-position: -220px -14px;
}

.l.sg,
.xl.sg {
  width: 22px;
  height: 15px;
  background-position: -88px -188px;
}

.l.sh,
.xl.sh {
  width: 22px;
  height: 11px;
  background-position: -110px -242px;
}

.l.si,
.xl.si {
  width: 22px;
  height: 11px;
  background-position: -132px -242px;
}

.l.sj,
.xl.sj {
  width: 22px;
  height: 16px;
  background-position: -66px -48px;
}

.l.sk,
.xl.sk {
  width: 22px;
  height: 15px;
  background-position: -198px -45px;
}

.l.sl,
.xl.sl {
  width: 22px;
  height: 15px;
  background-position: -44px -173px;
}

.l.sm,
.xl.sm {
  width: 22px;
  height: 17px;
  background-position: -22px -17px;
}

.l.sn,
.xl.sn {
  width: 22px;
  height: 15px;
  background-position: -176px -105px;
}

.l.so,
.xl.so {
  width: 22px;
  height: 15px;
  background-position: -176px -75px;
}

.l.sr,
.xl.sr {
  width: 22px;
  height: 15px;
  background-position: -176px -60px;
}

.l.ss,
.xl.ss {
  width: 22px;
  height: 11px;
  background-position: -44px -253px;
}

.l.st,
.xl.st {
  width: 22px;
  height: 11px;
  background-position: -66px -253px;
}

.l.sv,
.xl.sv {
  width: 22px;
  height: 13px;
  background-position: -22px -218px;
}

.l.sx,
.xl.sx {
  width: 22px;
  height: 15px;
  background-position: -132px -158px;
}

.l.sy,
.xl.sy {
  width: 22px;
  height: 15px;
  background-position: -110px -158px;
}

.l.sz,
.xl.sz {
  width: 22px;
  height: 15px;
  background-position: -88px -158px;
}

.l.ta,
.xl.ta {
  width: 22px;
  height: 11px;
  background-position: -176px -253px;
}

.l.tc,
.xl.tc {
  width: 22px;
  height: 11px;
  background-position: -198px -253px;
}

.l.td,
.xl.td {
  width: 22px;
  height: 15px;
  background-position: -22px -158px;
}

.l.tf,
.xl.tf {
  width: 22px;
  height: 15px;
  background-position: -132px -143px;
}

.l.tg,
.xl.tg {
  width: 22px;
  height: 14px;
  background-position: -220px -28px;
}

.l.th,
.xl.th {
  width: 22px;
  height: 15px;
  background-position: 0 -143px;
}

.l.tj,
.xl.tj {
  width: 22px;
  height: 11px;
  background-position: -264px -22px;
}

.l.tk,
.xl.tk {
  width: 22px;
  height: 11px;
  background-position: -264px -33px;
}

.l.tl,
.xl.tl {
  width: 22px;
  height: 11px;
  background-position: -264px -44px;
}

.l.tm,
.xl.tm {
  width: 22px;
  height: 15px;
  background-position: -154px -105px;
}

.l.tn,
.xl.tn {
  width: 22px;
  height: 15px;
  background-position: -154px -90px;
}

.l.to,
.xl.to {
  width: 22px;
  height: 11px;
  background-position: -264px -77px;
}

.l.tr,
.xl.tr {
  width: 22px;
  height: 15px;
  background-position: -154px -75px;
}

.l.tt,
.xl.tt {
  width: 22px;
  height: 13px;
  background-position: -220px -194px;
}

.l.tv,
.xl.tv {
  width: 22px;
  height: 11px;
  background-position: -264px -110px;
}

.l.tw,
.xl.tw {
  width: 22px;
  height: 15px;
  background-position: -22px -128px;
}

.l.tz,
.xl.tz {
  width: 22px;
  height: 15px;
  background-position: -132px -75px;
}

.l.ua,
.xl.ua {
  width: 22px;
  height: 15px;
  background-position: -132px -60px;
}

.l.ug,
.xl.ug {
  width: 22px;
  height: 15px;
  background-position: -132px 0;
}

.l.um,
.xl.um {
  width: 22px;
  height: 12px;
  background-position: -242px -98px;
}

.l.un,
.xl.un {
  width: 22px;
  height: 15px;
  background-position: -110px -98px;
}

.l.us,
.xl.us {
  width: 22px;
  height: 12px;
  background-position: -242px -50px;
}

.l.uy,
.xl.uy {
  width: 22px;
  height: 15px;
  background-position: -22px -98px;
}

.l.uz,
.xl.uz {
  width: 22px;
  height: 11px;
  background-position: -264px -209px;
}

.l.va,
.xl.va {
  width: 17px;
  height: 17px;
  background-position: -220px -56px;
}

.l.vc,
.xl.vc {
  width: 22px;
  height: 15px;
  background-position: 0 -98px;
}

.l.ve,
.xl.ve {
  width: 22px;
  height: 15px;
  background-position: -88px -83px;
}

.l.vg,
.xl.vg {
  width: 22px;
  height: 11px;
  background-position: -264px -253px;
}

.l.vi,
.xl.vi {
  width: 22px;
  height: 15px;
  background-position: -198px -105px;
}

.l.vn,
.xl.vn {
  width: 22px;
  height: 15px;
  background-position: -198px -90px;
}

.l.vu,
.xl.vu {
  width: 22px;
  height: 13px;
  background-position: -220px -218px;
}

.l.wf,
.xl.wf {
  width: 22px;
  height: 15px;
  background-position: -176px -150px;
}

.l.ws,
.xl.ws {
  width: 22px;
  height: 11px;
  background-position: -88px -264px;
}

.l.xk,
.xl.xk {
  width: 22px;
  height: 16px;
  background-position: -66px -32px;
}

.l.ye,
.xl.ye {
  width: 22px;
  height: 15px;
  background-position: -22px -83px;
}

.l.yt,
.xl.yt {
  width: 22px;
  height: 15px;
  background-position: -176px -15px;
}

.l.za,
.xl.za {
  width: 22px;
  height: 15px;
  background-position: 0 -203px;
}

.l.zm,
.xl.zm {
  width: 22px;
  height: 15px;
  background-position: -198px -150px;
}

.l.zw,
.xl.zw {
  width: 22px;
  height: 11px;
  background-position: -176px -264px;
}
