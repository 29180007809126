.optgroup {
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px;
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;
  background: #fff;
}
.label {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 1.5px;
  text-transform: uppercase;

  display: block;
  color: #b6bcc3;
}
.s {
  min-height: 40px;
}
.m {
  min-height: 48px;
}
.l,
.xl {
  min-height: 56px;

  padding-left: 20px;

  padding-right: 20px;
}
