.modal {
  opacity: 0;
  transition: all 0.3s ease;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
}

.afterOpen {
  opacity: 1;
}

.beforeClose {
  opacity: 1;
}

.overlay {
  align-items: flex-start;
}

.withTransform {
  transform: scale(0.75);
  will-change: transform;
}

.withTransform.afterOpen {
  transform: scale(1);
}

.withTransform.beforeClose {
  transform: scale(0.75);
}

@media screen and (min-width: 768px) {
  .overlay {
    align-items: center;
  }

  .modal {
    width: auto;
  }
}
