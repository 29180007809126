.arrow {
    display: block;
    width: 24px;
    height: 24px;

    background: url('https://alfabank.st/icons/icon_chevron-for-select-down_m_black.svg');
    background-size: cover;
    background-position: center;
    transition: transform 0.15s ease-in-out, opacity 0.2s ease;
}
.open {
    transform: rotate(180deg);
}
