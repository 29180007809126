.component {
    width: 100%;
    outline: none;
}
.field:not(.disabled) {
    cursor: pointer
}
.field:not(.disabled):hover .arrow {
            opacity: 0.7;
        }
.disabled {
    cursor: not-allowed;
}
/* TODO: Заюзать переменные из инпута */
.placeholder {
    color: #546272;
}
.hasLabel .placeholder {
    color: #b6bcc3;
}
.contentWrapper {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
}
.value {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}
.focusVisible {
    outline: 2px solid #007aff;
    outline-offset: 2px;
}
