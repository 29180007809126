.button {
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 25px auto 0;
  outline: none;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  font-weight: normal;
  background-color: #028dfd;
  color: #fff;
}

.button::after {
  content: '';
  will-change: left;
  position: absolute;
  transform: skewX(-22deg);
  width: 45px;
  height: 100%;
  opacity: 0;
  left: 0%;
  top: 0;
  animation-delay: 2s;
  pointer-events: none;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.6) 100%);
  animation-name: button-blick;
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.button:hover {
  filter: brightness(0.95);
}

.buttonLoading {
  opacity: 0.5;
}

.winWrap {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
  color: #fff;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: opacity 0.3s ease;
  padding: 15px;
}

.resultTitle {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
  margin: 0;
  margin-bottom: 20px;
  text-align: center;
}

.resultSubtitle {
  font-size: 16px;
  color: #fff;
  margin: 0;
  font-weight: normal;
  text-align: center;
  margin-bottom: 10px;
  max-width: 500px;
}

.linkToPrize {
  color: #fff;
  text-decoration: underline;
}

.copy {
  margin-top: 8px;
}

.closeWinIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

@keyframes button-blick {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0.3;
  }
  40% {
    opacity: 0;
    left: 100%;
  }
  100% {
    opacity: 0;
  }
}
