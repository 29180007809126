.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #000;
  min-height: 300px;
}

.loaderWrap {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #383b52;
}

.splashScreen {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(56, 59, 82, 0.8);
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.crashScreen {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(56, 59, 82, 0.8);
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.score {
  position: absolute;
  top: 5px;
  left: 5px;
  font-weight: bold;
  font-size: 24px;
  padding: 5px 10px;
  border-radius: 10px;
  font-family: monospace;
  user-select: none;
}

.attempt {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.attempt span {
  font-size: 24px;
  color: #00e200;
}

.buttonsWrap {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  outline: none;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  font-weight: normal;
}

.retryButton, .getPrizeButton {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 200px;
}

.retryButton {
  margin-bottom: 20px;
  background-color: #4d61fc;
}

.getPrizeButton {
  background-color: #e42525;
}

.getPrizeButton svg {
  width: 18px;
  height: 18px;
}

@media screen and (min-width: 768px) {
  .buttonsWrap {
    flex-direction: row;
  }

  .retryButton {
    margin-bottom: 0;
    margin-right: 20px;
  }

  .score {
    top: 10px;
    left: 10px;
    font-size: 32px;
  }
}
