.component {
  position: static;
  display: flex;
  min-width: 0;
  min-width: initial;
  height: 100%;
}
.option {
  display: flex;
  align-items: flex-start;
}
.flag {
  flex-shrink: 0;
  margin-top: 4px;

  margin-right: 8px;
}
.countryName {
  margin-right: 8px;
}
.dialCode {
  color: #546272;
}
