.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.row {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.chest {
  width: 100px;
  height: 120px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: transform 0.3s ease;
  transform: scale(1);
}

.chest:hover {
  transform: scale(1.05);
}

.chestBrown {
  background-image: url('https://cdn2.embedgames.app/CHEST/chest_brown_0.png');
}

.chestBrown:hover {
  background-image: url('https://cdn2.embedgames.app/CHEST/chest_brown_1.png');
}

.chestBlue {
  background-image: url('https://cdn2.embedgames.app/CHEST/chest_blue_0.png');
}

.chestBlue:hover {
  background-image: url('https://cdn2.embedgames.app/CHEST/chest_blue_1.png');
}

.chestGold {
  background-image: url('https://cdn2.embedgames.app/CHEST/chest_gold_0.png');
}

.chestGold:hover {
  background-image: url('https://cdn2.embedgames.app/CHEST/chest_gold_1.png');
}

.chestGreen {
  background-image: url('https://cdn2.embedgames.app/CHEST/chest_green_0.png');
}

.chestGreen:hover {
  background-image: url('https://cdn2.embedgames.app/CHEST/chest_green_1.png');
}

.chestRed {
  background-image: url('https://cdn2.embedgames.app/CHEST/chest_red_0.png');
}

.chestRed:hover {
  background-image: url('https://cdn2.embedgames.app/CHEST/chest_red_1.png');
}

.row0 {
  display: none;
}

.row1 {
  justify-content: center;
}

@media screen and (min-width: 360px) {
  .chest {
    width: 140px;
    height: 160px;
  }
}

@media screen and (min-width: 768px) {
  .row0 {
    display: flex;
  }

  .prizeSection {
    flex-grow: 1;
  }

  .emailInput {
    margin: 0;
  }
}
