.component {
    height: 100%;
    cursor: pointer;
    outline: none;
    position: relative;
    bottom: -1px;
}
.disabled {
    cursor: not-allowed;
}
.inner {
    position: relative;
    bottom: 1px;
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 12px;
    outline: none;
}
.l .inner,
.xl .inner {
    padding-left: 16px;
}
.focusVisible {
    outline: 2px solid #007aff;
    outline-offset: 2px;
}
