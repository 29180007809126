.checkmark {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;

  flex-shrink: 0;
  background: #0b1f35;
  background-size: cover;
  border-radius: 50%;
  margin: 0 8px 0 0;
  order: 0;
  width: 4px;
  height: 4px;
}
.selected {
  opacity: 1;
}
