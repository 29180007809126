.mainSection {
  flex-shrink: 0;
  flex-grow: unset;
}

.pointer {
  position: relative;
  z-index: 1;
  top: -12px;
  width: 30px;
  height: 30px;

}

.wheelWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  font-size: 10px;
  transform: translateY(-115px);
}

.rotationContainerWpapper {
  overflow: hidden;
  transform: scale(1.5);
  margin-bottom: 48px;
}

.rotationContainer {
  display: flex;
  width: 100%;
  transform: rotate(0deg) translateZ(0);
}

.wheelSvg {
  width: 320px;
}

.couponText {
  position: absolute;
  padding: 0 24px;
  width: 100%;
  top: 50%;
  user-select: none;
}

.couponTextInner {
  max-width: calc(100% / 2 - 40px);
  text-align: center;
  font-weight: 500;
  max-height: 50px;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .wheelWrapper {
    width: 620px;
    height: 100%;
    font-size: 16px;
    transform: none;
  }

  .rotationContainerWpapper {
    transform: scale(1);
    margin-bottom: 0;
  }

  .couponText {
    padding: 0 48px;
  }

  .couponTextInner {
    max-width: calc(100% / 2 - 76px);
  }

  .pointer {
    top: -20px;
  }

  .wheelSvg {
    width: 620px;
    height: 620px;
  }
}
