.game-modal-inner {
  width: 700px;
}

.eg-simple-cell * {
  box-sizing: content-box;
}

.PanelHeader,
.PanelHeader *,
.ModalPageHeader,
.ModalPageHeader *,
.SimpleCell {
  box-sizing: content-box;
}

.PanelHeader {
  min-height: 50px;
}

.ModalPage__in .swatches-picker {
  max-width: 100vw;
}

.Tabbar.hidden {
  display: none;
}

.single-panel .Panel__in {
  padding-bottom: 0 !important;
}

.single-panel .PanelHeader {
  position: absolute;
  z-index: 1000;
}

.Placeholder {
  background-color: var(--background_content);
  height: 100vh;
}

.custom-form-status .FormStatus__header,
.custom-form-status .FormStatus__content {
  color: #000 !important;
}

body[scheme='space_gray'] .form-status-error {
  background-color: #ff7e7e;
}

.TabbarItem {
  user-select: none;
}

.swatches-picker > div > div {
  background-color: var(--background_content);
}

.payment-status-icon {
  width: 48px !important;
  height: 48px !important;
}

.payment-status-icon > svg {
  width: 48px !important;
  height: 48px !important;
}

.crown-icon, .public-codes-icon {
  color: #ff00b1 !important;
}
