.mainSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.sliderWrapper {
  display: flex;
  position: relative;
  will-change: transform;
  transform: translate3d(0, 0, 0);
  transition: transform 3s cubic-bezier(0.25, 0.64, 0.28, 1);
  pointer-events: none;
  user-select: none;
}

.firstSlidesSection,
.secondSlidesSection {
  display: flex;
  will-change: transform;
}

.prize {
  flex-shrink: 0;
  width: 0;
  outline: none;
}

.prizeImage {
  width: 100%;
  flex-shrink: 0;
  margin: 0 auto 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.prizeTitle {
  padding: 0 4px;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  user-select: none;
}

.arrow {
  width: 85%;
  align-self: center;
  margin-bottom: 24px;
}

.rotatedArrow {
  transform: rotate(180deg);
  margin-top: 24px;
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .arrow {
    width: 65%;
    margin-bottom: 48px;
  }

  .rotatedArrow {
    margin-top: 48px;
  }

  .sliderWrapper {
    transition: transform 2s cubic-bezier(0.25, 0.64, 0.28, 1);
  }
}
