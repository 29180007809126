.optionsList {
  overflow: auto;
  width: 100%;
  outline: none;
  box-sizing: border-box;
}
.emptyPlaceholder {
  padding: 16px 12px;
  color: #546272;
}
.l .emptyPlaceholder,
.xl .emptyPlaceholder {
  padding: 24px 16px;
}
